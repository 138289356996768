"use client";

import type { SupportedLanguage } from "@/utils/i18n";
import { useEffect, useMemo, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import { languages } from "@/utils/i18n";
import { cn } from "@/utils/tw";
import { motion } from "framer-motion";
import { Nav, StickyFooterContent } from "../../Nav";
import { HeaderSlider } from "./HeaderSlider";
const heightTopbar = 90; // if we are sliding out a nav bar at the top of the screen, this will be it's height
const heightFooter = 90; // if we are sliding out a nav bar at the top of the screen, this will be it's height

export const Header = ({
  onChangeEnded,
  onChangeStarted,
  lang
}: {
  lang: SupportedLanguage;
  onChangeEnded?: (key: string) => void;
  onChangeStarted?: (key: string) => void;
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const router = useRouter();
  useEffect(() => {
    let lastScroll = 0;
    const handleScroll = () => {
      const currentScroll = window.scrollY;
      setIsVisible(currentScroll < lastScroll || currentScroll <= heightTopbar);
      lastScroll = currentScroll;
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const fullPathName = usePathname();
  const pathName = useMemo(() => {
    const [_empty, language, path] = (fullPathName || "/").split("/");
    if (language && languages.map(lang => lang.id as string).includes(language)) {
      return `/${path || ""}`;
    }
    return `/${language || ""}`;
  }, [fullPathName]);
  const [current, setCurrent] = useState(pathName);
  return <>
      <Nav lang={lang} onChangeEnded={onChangeEnded} override={current} data-sentry-element="Nav" data-sentry-source-file="Header.tsx" />
      <motion.footer className={cn("bottom-0 z-20 w-dvw bg-beige-3 transition-all lg:hidden", {
      fixed: true
    })} animate={{
      y: isVisible ? 0 : heightFooter
    }} transition={{
      duration: 0.2,
      delay: 0,
      ease: "easeInOut"
    }} data-sentry-element="unknown" data-sentry-source-file="Header.tsx">
        <div className="container w-full py-4">
          <StickyFooterContent data-sentry-element="StickyFooterContent" data-sentry-source-file="Header.tsx" />
        </div>
      </motion.footer>
      <div className="overflow-hidden bg-beige-3 pt-[90px] md:px-16 xl:pt-36">
        <HeaderSlider initialActiveKey={pathName || "/"} onChange={key => {
        if (key !== pathName) {
          router.push(key);
        }
        onChangeEnded?.(key);
      }} onChangeStarted={key => {
        onChangeStarted?.(key);
        setCurrent(key);
      }} data-sentry-element="HeaderSlider" data-sentry-source-file="Header.tsx" />
      </div>
    </>;
};